<template>
  <div id="app" class="app">
    <div class="center">
      <img class="logo" src="./assets/logoPng.png" />
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    //
  }),
};
</script>

<style scoped>
.logo {
  animation: entrada 1s ease-in-out;
}
@keyframes entrada {
  0% {
    transform: translateX(-400%);
    opacity: 0;
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    opacity: 100;
  }
}
.center {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background: linear-gradient(
      -45deg,
      rgb(236, 236, 237) 0%,
      rgb(179, 175, 175) 21%,
      rgb(206, 207, 207) 48%,
      rgb(222, 226, 228) 78%,
      rgb(245, 246, 247) 100%
    )
    no-repeat;
  background-size: 300% 300%;
  animation: gradiente 100s ease infinite;
}
@keyframes gradiente {
  0% {
    background-position: 0 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 100%;
  }
}

.app {
  background-color: rgb(215, 224, 229);
}

</style>
